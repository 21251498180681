<template>
  <b-modal
    modal
    v-model="onControl"
    title="TRACKING"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    hide-footer
    @hidden="close"
    :no-close-on-backdrop="true"
  >

    <b-row class="mt-1 px-">
        <!-- Client -->
        <b-col cols="6">
            <h5 class="text-left">Client</h5>
            <p
                class="rounded text-primary border-primary font-medium-1 text-center py10"
            >
                {{ rowClient.lead_name }}
            </p>
        </b-col>
        <!-- Account -->
        <b-col cols="6">
            <h5 class="text-left">Account</h5>
            <p
                class="rounded text-primary border-primary font-medium-1 text-center py10"
            >
                {{ rowClient.accounts[0].account }}
            </p>
        </b-col>
    </b-row>
    <b-table
      id="tracking-list"
      ref="trackingList"
      no-border-collapse
      class="position-relative"
      :fields="fields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="trackingData"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(created_at)="data">
        <span>{{ data.item.created_at | myGlobalDay }}</span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import ClientService from "../services/client.service";
export default {
  props: {
    rowClient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      fields: [
        {
          key: 'created_at',
          label: 'Modification Date',
        },
        {
          key: 'user',
          label: 'User',
        },
        {
          key: 'list',
          label: 'List',
        },
      ],
      trackingData: [],
      isBusy: false,
    }
  },
  async created() {
    this.onControl = true
    await this.getTrackingData()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getTrackingData() {
      try {
        this.isBusy = true
        const data = await ClientService.getTrackingData({ id: this.rowClient.lead_id })
        if (data.status === 200) {
          this.trackingData = data.data
          this.isBusy = false
        }
      } catch (e) {
        this.isBusy = false
        this.showErrorSwal(e)
      }
    },
  },
}
</script>

<style scoped>

</style>
