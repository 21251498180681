<template>
  <div>
    <b-card no-body class="mb-2">
      <div>
        <filter-slot
          :filter="visibleFilters"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reset-all-filters="resetAllFilters()"
          @reload="$refs.clientsList.refresh()"
        >
          <template #custom-vselect>
            <FilterStatusAccount :filters="filter[3]" />
          </template>
          <template #table>
            <b-table
              ref="clientsList"
              slot="table"
              :busy.sync="isBusy"
              :fields="visibleFields"
              :items="search"
              :filter="visibleFilters"
              :per-page="paginate.perPage"
              :current-page="paginate.currentPage"
              no-provider-filtering
              responsive="sm"
              small
              show-empty
              sticky-header="65vh"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(lead_name)="data">
                <div
                  class="d-flex flex-column justify-content-center align-items-start"
                >
                  <router-link
                    class
                    target="_blank"
                    :to="`/taxresearch/clients/account/${data.item.accounts[0].account_id}`"
                    >{{ data.item.lead_name }}</router-link
                  >
                  {{ data.item.mobile }}
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    class="d-flex justify-content-between align-items-center"
                    style="padding-bottom: 4px"
                  >
                    <status-account
                      :account="account"
                      :text="true"
                    ></status-account>
                  </span>
                  <br />
                  <span v-if="data.item.user_responsible" class="text-warning">
                    <tabler-icon
                      :icon="
                        data.item.user_responsible === currentUser.user_id
                          ? 'StarIcon'
                          : 'LockIcon'
                      "
                      size="15"
                    />
                    <strong>CEO's client</strong>
                  </span>
                </div>
              </template>
              <!-- <template #cell(mobile)="data">
                <p class="mb-0 font-weight-bold">
                  {{ data.item.mobile }}
                </p>
              </template> -->
              <template #cell(account)="data">
                <div
                  class="d-flex flex-column justify-content-center align-items-start"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    style="padding-bottom: 4px"
                  >
                    {{ account.account }}
                  </span>
                </div>
                <b-badge
                  :key="index"
                  v-if="data.item.claims != 0"
                  variant="light-info"
                >
                  IN CLAIM ({{ data.item.claims }})
                </b-badge>
              </template>
              <!-- <template #cell(status)="data">
                <span
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  class="d-flex justify-content-between align-items-center"
                  style="padding-bottom: 4px"
                >
                  <status-account
                    :account="account"
                    :text="true"
                  ></status-account>
                </span>
              </template> -->
              <template #cell(fee_charges)="data">
                <div
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  class="d-flex flex-column justify-content-center align-items-start"
                  style="padding-bottom: 4px"
                >
                  ${{ account.charges }}
                </div>
              </template>
              <template #cell(mp)="data">
                <div
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  class="d-flex flex-column justify-content-start align-items-start"
                  style="padding-bottom: 4px"
                >
                  ${{ account.monthly_amount }}
                </div>
              </template>
              <template #cell(lp)="data">
                <div
                  class="d-flex flex-column justify-content-start align-items-start"
                  style="position: relative"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    style="display: flex"
                  >
                    <b-img
                      v-if="account.state_lp == 1"
                      :src="`${baseImg}/assets/images/paragon/last_payment/lastpayment-out.ico`"
                      style="
                        margin-right: 5px;
                        height: 15px;
                        margin-top: 2px;
                        width: 15px;
                      "
                    />
                    <b-img
                      v-else
                      :src="`${baseImg}/assets/images/paragon/last_payment/lastpayment-in.ico`"
                      style="
                        margin-right: 5px;
                        height: 15px;
                        margin-top: 2px;
                        width: 15px;
                      "
                    />
                    {{
                      account.last_payment == "-" ? "" : account.last_payment
                    }}
                  </span>
                </div>
              </template>
              <template #cell(pt)="data">
                <div>
                  <ul style="padding-left: 0px; margin-bottom: 0px">
                    <li
                      v-for="(account, index) in data.item.accounts"
                      :key="index"
                      style="list-style: none"
                    >
                      <status-payment :account="account" />
                    </li>
                  </ul>
                </div>
              </template>

              <!-- COLUMN OTHER PROGRAMS -->
              <template #cell(qty_services)="{ item }">
                <ClientPrograms
                  :clientData="item"
                  :programName="item.accounts[0].program"
                  :programId="item.accounts[0].program_id"
                />
              </template>

              <template #cell(advisor)="data">
                <div
                  class="d-flex flex-column justify-content-start align-items-start"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    style="padding-bottom: 4px"
                  >
                    <feather-icon
                      v-if="account.state_advisor === 1"
                      icon="CircleIcon"
                      size="13"
                      :style="`color: #00CC00; border-color: #00CC00; background: #00CC00; border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                    />
                    <feather-icon
                      v-if="account.state_advisor === 2"
                      icon="CircleIcon"
                      size="13"
                      :style="`color: #00CC00; border-color: #00CC00; background: #00CC00; border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                    />
                    <feather-icon
                      v-if="account.state_advisor === 0"
                      icon="CircleIcon"
                      size="13"
                      :style="`color: rgb(204, 204, 204); border-color: #00CC00; background: rgb(204, 204, 204); border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                    />
                    <span>{{ account.advisor_name }}</span></span
                  >
                </div>
              </template>
              <template #cell(ln)="data">
                <div
                  class="d-flex flex-column justify-content-start align-items-start"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    :style="
                      account.s_note <= 1
                        ? 'color:#000000'
                        : account.s_note <= 2
                        ? 'color: #FF8000'
                        : 'color:#FF0000'
                    "
                    style="padding-bottom: 4px"
                    >{{ account.last_note }}
                  </span>
                </div>
              </template>
              <template #cell(ut)="data">
                <div
                  class="d-flex flex-column justify-content-start align-items-start"
                  style="margin-left: 10px"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    :style="
                      account.task <= 5
                        ? 'color:#000000; position: relative;'
                        : account.task <= 10
                        ? 'color: #FF8000; position: relative;'
                        : 'color:#FF0000; position: relative;'
                    "
                  >
                    <b-img
                      :src="`${baseImg}/assets/images/paragon/tasks/uncompletetasks.ico`"
                      style="
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                      "
                    />
                    <span
                      style="font-size: 11px"
                      :style="isDarkSkin ? 'color: #fff' : ''"
                      >{{ account.task }}</span
                    >
                  </span>
                </div>
              </template>
              <template #cell(creation_date)="data">
                <span
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  >{{ account.created_at | myGlobal }}</span
                >
              </template>
              <!-- Column SERVICES -->
              <template
                v-if="
                  currentUser.role_id == 1 ||
                  currentUser.role_id == 2 ||
                  currentUser.role_id == 3
                "
                #cell(services)="data"
              >
                <actions-table
                  v-if="
                    data.item.user_responsible == currentUser.user_id ||
                    data.item.user_responsible == null
                  "
                  :options="optionsService"
                  :row-data="data.item"
                  @modalChangeService="modalChangeService"
                  @modalAddService="modalAddService"
                />
              </template>
              <template #cell(actions)="data">
                <actions-table
                  v-if="
                    data.item.user_responsible == currentUser.user_id ||
                    data.item.user_responsible == null
                  "
                  :options="optionsActions"
                  :row-data="data.item"
                  @modalTrackingListMain="openModalTrackingList"
                  @reloadTable="$refs.clientsList.refresh()"
                />
              </template>
            </b-table>
          </template>
        </filter-slot>
      </div>
    </b-card>
    <modal-tracking-list-main
      v-if="openModalTracking"
      :row-client="trackingDataModal"
      @close="closeModalTrackingList"
    />
    <!-- Modal Add Services -->
    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />
    <!-- Component Commons -->
    <component
      :is="modalData.programs.programSelected"
      v-if="modal.programs"
      :type-modal="modalData.programs.typeModal"
      :sales-client="saleClientP"
      :programs-all="programsAll"
      :users-services="usersServices"
      @closeModal="hideModalProgram"
      @changeProgram="openModalProgram"
    />
  </div>
</template>

<script>
// Componenrts
import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
import ClientPrograms from "@/views/commons/components/client-programs/ClientPrograms.vue";

import dataFields from "./fields.data";
import dataFilters from "./filters.data";
import ClientService from "../services/client.service";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import ModalTrackingListMain from "./ModalTrackingMainList.vue";
import { mapGetters, mapActions } from "vuex";
import ActionsTable from "./ActionsTable.vue";
import { modifyVisibility } from "@/helpers/fields-table";

import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import ModalAddService from "@/views/commons/components/digital/modals/ModalAddService.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";
import Vue from "vue";

export default {
  components: {
    StatusPayment,
    ActionsTable,
    ModalTrackingListMain,

    BusinessModal,
    CreditExpertsModal,
    BoostCreditModal,
    GeneralSupportModal,
    KeyBookModal,
    ParagonModal,
    SpecialistModal,
    TaxResearchModal,
    DebtSolutionModal,
    ModalAddService,
    StatusAccount,
    NcrLeadsService,
    FilterStatusAccount,
    ClientPrograms,
  },
  data() {
    return {
      filter: dataFilters,
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      openModalTracking: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "account_id",
      startPage: null,
      toPage: null,
      isBusy: false,
      items: [],
      fields: dataFields,
      order: "desc",
      actionsOptions: ["Main", "Archived", "CallsRound", "TrackingList"],
      optionsService: ["addService", "changeService"],
      trackingDataModal: {},
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },

      saleClientP: {},
      usersServices: [],
      programsAll: [],
      modal: {
        programs: false,
      },
      modalOpenAddService: false,
      detailsClient: {},
      typeModal: null,
      typeAddChange: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      modifyVisibility(
        this.fields,
        "advisor",
        this.currentUser.role_id !== 3 || this.moduleId === 2
      );
      modifyVisibility(
        this.fields,
        "ln",
        (this.moduleId !== 2 && this.moduleId !== 4 && this.moduleId !== 6) ||
          (this.moduleId === 6 && this.currentUser.role_id !== 4)
      );
      modifyVisibility(
        this.fields,
        "actions",
        this.currentUser.role_id === 1 || this.currentUser.role_id === 2
      );
      modifyVisibility(
        this.fields,
        "services",
        this.currentUser.role_id === 1 ||
          this.currentUser.role_id === 2 ||
          this.currentUser.role_id === 3
      );
      return this.fields.filter((column) => column.visible);
    },
    optionsActions() {
      return this.actionsOptions.filter(
        (item) => item !== this.$route.meta.list_table
      );
    },
    visibleFilters() {
      this.filter[2].visible = this.currentUser.role_id !== 3;
      return this.filter.filter((column) => column.visible);
    },
    paymentType: {
      get() {
        return this.filter[4].model;
      },
      set(value) {
        this.filter[4].model = value;
      },
    },
    paymentDay: {
      get() {
        return this.filter[5].model;
      },
      set(value) {
        this.filter[5].visible = value;
      },
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    paymentType(newVal) {
      if (newVal === 1) {
        this.paymentDay = true;
      } else {
        this.paymentDay = false;
        this.filter[5].model = 0;
      }
    },
  },
  created() {
    this.usersPrograms();
    this.usersAllPrograms();
    this.getAllStatusClientAccount();
  },
  methods: {
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    async createEvent(data) {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: data.lead_id,
      });
      this.event = response.id;
    },
    openModalProgram: async function (data) {
      const param = {
        prices: null,
        observation: "Service",
        contract: 1,
        program: data.program.id,
        fee: null,
        suggested: null,
        otherpricesp: null,
        event: this.event,
        json_noce: null,
        stateid: 0,

        // Diferents to add change Services
        account: this.detailsClient.accounts[0].account_id,
        captured: data.captured.id,
        seller: data.seller.id,
        type: this.typeAddChange,
        user_id: this.currentUser.user_id,
        module: this.moduleId,
        id_score: null,
        json_ce: null,
        type_client: "CLIENT",
      };
      try {
        let result = await LeadService.insertSaleMade(param);
        if (result.status == 200) {
          this.modalOpenAddService = false;
          this.$router
            .push({ name: "sales-made-tax-research-client-pending" })
            .catch((err) => {
              console.log(err);
            });
        }
      } catch (error) {
        this.showErrorSwal(
          "Please, verify if the client already has the service, otherwise contact support.",
          "Error"
        );
      } finally {
        this.removePreloader();
      }
    },
    modalAddService: async function (data) {
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );
      if (result.value) {
        this.typeAddChange = 1;
        await this.createEvent(data);
        this.modalGeneralService(data, 3);
      }
    },
    async hideModalProgram(refresh) {
      if (refresh) {
        // await this.updateRow();
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      }

      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.removePreloader();
    },
    modalChangeService: async function (data) {
      const result = await this.showConfirmSwal("Are you sure change service?");
      if (result.value) {
        this.typeAddChange = 2;
        await this.createEvent(data);
        this.modalGeneralService(data, 4);
      }
    },
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.typeModal = type;
      Vue.set(this.detailsClient, "event_id", this.event)
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    async search(ctx) {
      try {
        let orderBy = 5;
        let sortDirection = "desc";
        if (ctx.sortBy === "lead_name") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 2;
        } else if (ctx.sortBy === "account") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 4;
        } else if (ctx.sortBy === "fee_charges") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 42;
        } else if (ctx.sortBy === "pt") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 48;
        } else if (ctx.sortBy === "mp") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 43;
        } else if (ctx.sortBy === "creation_date") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 5;
        }
        const params = {
          per_page: this.paginate.perPage,
          orderby: orderBy,
          order: sortDirection,
          from: this.filter[0].model,
          to: this.filter[1].model,
          text: this.filterPrincipal.model,
          state: this.state,
          status: this.filter[3].model,
          advisor: this.filter[2].model,
          type: this.paymentType,
          day: this.paymentDay,
          rol_id: this.currentUser.role_id,
          program: 5,
          list: this.$route.meta.list_table,
        };
        const data = await ClientService.getClients(params, ctx.currentPage);
        data.data.data.map((data) => {
          // eslint-disable-next-line no-param-reassign
          data.accounts = JSON.parse(data.accounts);
          data.accounts.map((val) => {
            if (val.charges === null) val.charges = 0;
          });
        });
        const items = data.data.data;
        // Must return an array of items or an empty array if an error occurred
        this.items = items;
        console.log("items", this.items);
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalData = data.data.total;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;

        return this.items;
      } catch (error) {
        this.showErrorSwal(error);
        return [];
      }
    },
    resetAllFilters() {
      this.filter.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.clientsList.refresh();
    },
    async usersPrograms() {
      try {
        const params = {
          idmodule: this.moduleId,
          iduser: this.currentUser.user_id,
          idrole: this.currentUser.role_id,
        };
        const data = await ClientService.usersPrograms(params);
        this.filter[2].options = data;
      } catch (error) {
        console.log(error);
      }
    },
    usersAllPrograms: async function () {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.moduleId,
        });
        this.usersServices = data.data;
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },
    searchPrograms: async function (lead_id) {
      try {
        const response = await ClientService.searchClientPayment({
          id: lead_id,
        });
        if (response.status == 200) {
          let programs = response.data;
          const data = await ClientService.programs();
          this.programsAll = data.data;
          programs.sort(function (a, b) {
            return a.program_id - b.program_id;
          });
          for (var i = 0; i < this.programsAll.length; i++) {
            for (var y = 0; y < programs.length; y++) {
              if (this.programsAll[i].id == programs[y].program_id) {
                this.programsAll.splice(i, 1);
              }
            }
          }
          var sper = this.currentUser.sale_permission;
          var spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    closeModalTrackingList() {
      this.openModalTracking = false;
    },
    openModalTrackingList(item) {
      this.trackingDataModal = item;
      this.openModalTracking = true;
    },
    async getAllStatusClientAccount() {
      this.filter[3].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.filter[3].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.filter[3].options.push(...data);
    },
  },
};
</script>
