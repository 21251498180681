<template>
  <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
    <template #button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="16"
        class="align-middle text-body"
      />
    </template>

    <!-- historySMS -->
    <template  v-for="(item, index) in arrLists">
      <b-dropdown-item
        v-if="options.includes(item.type)"
        :key="index"
        variant="primary"
        v-b-tooltip.hover.left="item.tittle"
        v-b-modal.modal-primary
        @click="onRowMoveList(rowData, item)"
      >
        <feather-icon :icon="item.icon" />
        <span
          class="align-middle ml-50"
        >{{item.tittle}}</span>
      </b-dropdown-item>
    </template>
    <b-dropdown-item
      v-if="options.includes('TrackingList')"
      v-b-tooltip.hover.left="'Tracking'"
      v-b-modal.modal-primary
      @click="onRowTrackingList(rowData)"
    >
      <feather-icon icon="EyeIcon" />
      <span class="align-middle ml-50">Tracking</span>
    </b-dropdown-item>

    <!-- Add Service -->
    <b-dropdown-item
      v-if="options.includes('addService')"
      variant="warning"
      @click="onRowAddService(rowData)"
    >
      <feather-icon icon="FilePlusIcon" />
      <span class="align-middle ml-50">Add service</span>
    </b-dropdown-item>

    <!-- Change Service -->
    <b-dropdown-item
      v-if="options.includes('changeService')"
      variant="success"
      @click="onRowChangeService(rowData)"
    >
      <feather-icon icon="EditIcon" />
      <span class="align-middle ml-50">Change service</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInvalidFeedback, BButton } from 'bootstrap-vue';
import { mapGetters, mapActions } from "vuex";
import ClientService from '@/views/tax-research/views/clients/services/client.service'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    rowData: {
      required: true
    },
  },
  data() {
    return {
      arrLists: [
        {tittle: 'Send to Main', icon: 'HomeIcon', type: 'Main', question: 'Are you sure you want to send it to the Main?', route: 'tax-research-clients-main'},
        {tittle: 'Send to Archived', icon: 'FileIcon', type: 'Archived', question: 'Are you sure you want to archive it?', route: 'tax-research-clients-archived'},
        {tittle: 'Send to Calls Round', icon: 'PhoneIcon', type: 'CallsRound', question: 'Are you sure you want to send it to round calls?', route: 'tax-research-clients-calls'},
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    ...mapActions({
      A_SET_COUNTERS_TABS_CLIENTS: 'TaxResearchClients/A_SET_COUNTERS_TABS_CLIENTS',
    }),
    onRowMoveList(item, info) {
      this.$swal({
        title: info.question,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async(result) => {
          if (result.value) {
            const params = {
              client_id: item.lead_id,
              iduser: this.currentUser.user_id,
              move: info.type
            };
            const data = await ClientService.moveList(params);
            if (data.status === 200) {
              const counterValue = await ClientService.getClientsCounter();
              if (counterValue) {
                this.A_SET_COUNTERS_TABS_CLIENTS(counterValue.data);
              }
              this.$emit("reloadTable");
            }
          }
        });
    },
    onRowTrackingList(item) {
      this.$emit("modalTrackingListMain", item);
    },
    onRowAddService(item) {
      this.$emit("modalAddService", item);
    },
    onRowChangeService(item) {
      this.$emit("modalChangeService", item);
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.sidebar-xl {
  width: 90rem;
}
</style>
